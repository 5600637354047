.license-plate__rectangle {
  border: 5px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
}

.license-plate__rectangle--country {
  height: 100%;
  width: 16%;
  background-color: blue;
  display: flex;
  flex-direction: column;
}

.license-plate__rectangle--country > div {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.license-plate__rectangle--id {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
